export interface CommentFormStoreState {
  postId: number | null
  parent: {
    default: string
    current: string
    id: number | null
  }
}
export const useCommentFormStore = defineStore('CommentForm', {
  state: () =>
    ({
      postId: null,
      parent: {
        default: 'comments',
        current: 'comments',
        id: null,
      },
    }) as CommentFormStoreState,
  getters: {},
  actions: {
    setPostId(id: number | null) {
      this.postId = id
    },
    setParent(id: string | null) {
      if (!id || id === this.parent.id?.toString()) {
        this.parent.current = this.parent.default
        this.parent.id = null
        return
      }
      this.parent.current = `comment-${id}`
      this.parent.id = parseInt(id)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCommentFormStore, import.meta.hot))
}
